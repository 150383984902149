<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer : 추천 상품 설정 -->
    <section id="modalResultGoods" class="layer_dialog modalin">
      <h2 class="mb20">추천 상품 설정</h2>
      <p>
        [Step1. 추천 상품 설정] 등록된 상품과 설정에 따라 선택이 가능합니다.
      </p>

      <div class="mt20 mb10">
        <select name="" v-model="recommend_type" style="width: 300px">
          <option value="none">등록된 상품 추천</option>
          <option value="choice" v-show="is_show_choice">
            사용자 선택에 따른 순위별 상품 추천
          </option>
        </select>
      </div>

      <div v-if="recommend_type === 'none'" class="padding1">
        <table class="tbl_list">
          <caption>
            추천 상품 목록
          </caption>
          <colgroup>
            <col style="width: 5%" />
            <col style="width: 14%" />
            <col style="width: auto" />
            <col style="width: 14%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col" colspan="2">상품이미지</th>
              <th scope="col">상품정보</th>
              <th scope="col">판매가</th>
              <th scope="col">진열상태</th>
              <th scope="col">판매상태</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                Array.isArray(this.product_list) !== true ||
                this.product_list.length === 0
              "
            >
              <td colspan="6" class="nodata">
                등록된 상품이 없습니다.<br />(Step1. 추천 상품 설정 메뉴에서
                상품을 등록해주세요)
              </td>
            </tr>
            <tr v-for="(item, key) in this.product_list" :key="key">
              <td>
                <input
                  type="checkbox"
                  name=""
                  v-model="item.checked"
                  :value="item.product_no"
                  :disabled="item.disabled"
                  @change="setProduct($event, key)"
                />
              </td>
              <td>
                <p class="thumb">
                  <img
                    v-if="
                      item.mall_product_image !== null &&
                      item.mall_product_image.image_url
                    "
                    :src="item.mall_product_image.image_url"
                    alt=""
                  />
                </p>
              </td>
              <td class="ta_l customTextStart">
                <strong class="goods_name">{{ item.product_name }}</strong>
                <span class="goods_code"
                  >상품코드 {{ item.mall_product.product_code }}</span
                >
              </td>
              <td>
                <strong>{{ numberFormat(item.mall_product.price) }}</strong>
              </td>
              <td>
                <strong v-if="item.mall_product.display == 'T'">진열함</strong>
                <strong v-else class="purpleFont">진열안함</strong>
              </td>
              <td>
                <strong v-if="item.mall_product.selling == 'T'">판매함</strong>
                <strong v-else class="purpleFont">판매안함</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination
          v-if="this.product_list.length > 0 && this.paging.total_count > 0"
          :per_page="this.paging.per_page"
          :total_count="this.paging.total_count"
          @page-change="pageChange"
        />
      </div>

      <div
        v-if="
          recommend_type === 'choice' &&
          Array.isArray(this.product_list) === true &&
          this.product_list.length > 0
        "
      >
        <select name="" v-model="rank" style="width: 100px">
          <option
            v-for="(item, key) in this.product_list"
            :key="key"
            :value="key + 1"
          >
            {{ key + 1 }}위
          </option>
        </select>
        상품을 노출합니다.
      </div>
      <div
        v-if="
          recommend_type === 'choice' &&
          Array.isArray(this.product_list) === true &&
          this.product_list.length <= 0
        "
        style="color: #888"
      >
        등록된 상품이 없습니다.<br />
        (Step1. 추천 상품 설정 메뉴에서 상품을 등록해주세요)
      </div>

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 추천 상품 설정 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';

import Pagination from '@/components/layout/Pagination.vue';

export default {
  mixins: [common],
  inheritAttrs: false,
  components: {
    Pagination,
  },
  data: () => ({
    show_modal: true,
    recommend_type: 'none',
    rank: 1,
    paging: {
      offset: 0,
      per_page: 50,
      total_count: 0,
    },
    product_list: [],
    product_id_list: [],
    saved_product_list: [],
    saved_product_id_list: [],
    product: {},
    recommend_product: {},
    is_show_choice: true,
  }),
  props: ['survey_config_info', 'result', 'index', 'layout_type'],
  created: async function () {},
  computed: {
    ...mapState('qna_product', ['products']),
  },
  mounted: async function () {
    await this.init();
  },
  methods: {
    init: async function () {
      if (this.survey_config_info.question_type == 'none') {
        this.is_show_choice = false;
      }
      await this.getProductList();
      const recommend_index = this.index - 1;
      if (this.result.recommend_goods[recommend_index] !== undefined) {
        this.recommend_type = this.result.recommend_goods[recommend_index].type;
        this.rank = this.result.recommend_goods[recommend_index].rank;

        if (this.recommend_type === '') this.recommend_type = 'none';
        if (this.rank === '') this.rank = 1;

        if (
          Array.isArray(this.result.recommend_goods[recommend_index].list) &&
          this.result.recommend_goods[recommend_index].list.length !== 0
        ) {
          for (const [key, item] of Object.entries(
            this.result.recommend_goods[recommend_index].list,
          )) {
            this.saved_product_list.push(item);

            if (this.product_id_list.includes(item.product_no)) {
              const index = this.product_id_list.indexOf(item.product_no);
              this.product_list[index].checked = true;
            }
          }
        }
      }

      if (this.result.recommend_goods !== undefined) {
        for (const [key, item] of Object.entries(this.result.recommend_goods)) {
          if (item.list === undefined) {
            continue;
          }
          if (Number(key) === recommend_index) {
            continue;
          }
          for (const product of Object.entries(item.list)) {
            if (this.product_id_list.includes(product.product_no)) {
              const index = this.product_id_list.indexOf(product.product_no);
              this.product_list[index].disabled = true;
            }
          }
        }
      }

      if (this.saved_product_list.length !== 0) {
        for (const [key, item] of Object.entries(this.saved_product_list)) {
          const index = this.product_list.findIndex(obj => obj.id === item.id);

          // 저장 했다가 상품을 삭제 한 경우.
          if (index === -1) {
            this.saved_product_list.splice(key, 1);
          }
        }
      }
      if (this.product_list.length == 0) {
        this.rank = 0;
      }

      if (this.product_list.length < this.rank) {
        this.rank = 1;
      }
    },
    setResult: function () {
      this.recommend_product = this.result;
      const recommend_index = this.index - 1;
      // 초기화
      this.recommend_product.recommend_goods[recommend_index].list = [];

      if (this.recommend_type === 'none') {
        if (this.saved_product_list.length === 0) {
          alert('추천 상품을 선택해주세요.');
          return false;
        }

        for (const [key, item] of Object.entries(this.saved_product_list)) {
          this.recommend_product.recommend_goods[recommend_index].list.push(
            item,
          );
        }
        this.recommend_product.recommend_goods[recommend_index].rank = '';
      } else {
        if (this.rank == 0) {
          alert('순위를 선택해주세요.');
          return false;
        }
        this.recommend_product.recommend_goods[recommend_index].rank =
          this.rank;
      }

      this.recommend_product.layout_type = this.layout_type;
      this.recommend_product.recommend_goods[recommend_index].type =
        this.recommend_type;

      this.$emit('updateData', this.recommend_product, this.index);
      this.show_modal = false;
    },
    setProduct: function (e, index) {
      const checked = e.target.checked;
      const product_no = e.target.value;
      const item = this.product_list[index];

      const save_index_of = this.saved_product_list.findIndex(
        obj => obj.product_no == product_no,
      );

      if (checked === true && save_index_of === -1) {
        if (this.saved_product_list.length >= 1) {
          alert(`선택 하실 수 있는 상품의 수는 1개 입니다.`);
          e.target.checked = false;
          return false;
        }

        let image_url = '';

        if (
          item.mall_product_image !== null &&
          item.mall_product_image !== undefined
        ) {
          image_url = item.mall_product_image.image_url;
        }

        this.saved_product_list.push({
          id: item.id,
          product_no: item.product_no,
          product_name: item.product_name,
          product_code: item.mall_product.product_code,
          product_result_item: item.product_result_item,
          price: item.mall_product.price,
          display: item.mall_product.display,
          selling: item.mall_product.selling,
          sold_out: item.mall_product.sold_out || '',
          image_url: image_url,
        });
      } else if (checked === false && save_index_of !== -1) {
        this.saved_product_list.splice(save_index_of, 1);
      }
    },
    pageChange: async function (offset) {
      this.paging.offset = offset;
      await this.getProductList();
    },
    getProductList: async function () {
      await this.$store.dispatch('qna_product/getProducts', {
        offset: this.paging.offset,
        limit: this.paging.per_page,
      });

      this.paging.total_count = this.products.total_count;

      // this.product_list = this.products.list;
      this.product_list = [];
      for (const product of this.products.list) {
        // 카페24 이미지를 <img> 불러올때 오류 발생으로 수정 ( 도메인명 변경 )
        if (
          product.mall_product_image &&
          product.mall_product_image.image_url
        ) {
          let _image_href_array = product.mall_product_image.image_url.split('/');

          if ( _image_href_array[3] == 'web' && _image_href_array[4] == 'product') {
            _image_href_array[2] = `${this.$store.state.common.mall_id}.cafe24.com`;
          }
          product.mall_product_image.image_url = _image_href_array.join('/');
        }
        this.product_list.push(product);
      }

      for (const [key, item] of Object.entries(this.product_list)) {
        if (
          this.saved_product_list.findIndex(
            obj => obj.product_no == item.product_no,
          ) !== -1
        ) {
          this.product_list[key].checked = true;
        }
        this.product_id_list.push(item.product_no);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.purpleFont {
  color: #8041d9;
}

.padding1 {
  padding: 1px;
}
</style>
